import { appAssets } from 'constants/assets';
import React, { useEffect, useState } from 'react';
import styles from '../CardWidget/CardWidget.module.scss';
import { Widget } from 'models/WidgetModel';
import CardTitle from '../CardTitle/CardTitle';
import CardHeaderActions from '../CardHeaderActions/CardHeaderActions';
import DataNotFound from 'components/DataNotFound/DataNotFound';
import LoadingSpinner from 'core-ui/LoadingSpinner/LoadingSpinner';
import { useStore } from 'store/useGlobalStore';
import libraryComponents from 'components';
import { showWarningValidation, warningValidData } from 'utils/validators/validatorDataList';
import DataTimeframe from '../DataTimeframe/DataTimeframe';
import { useWidgetContext } from 'contexts/WidgetProvider';
import { getHierarchyFiltersLabelData, getAdvancedFiltersLabelData } from 'helpers/utilities';
import CardFooterActions from 'components/CardFooterActions/CardFooterActions';

interface WidgetToRenderProps {
  removeWidget?: (id: number) => void;
  editWidget: (widget: any) => void;
  widget: Widget;
  validateData: (res: any) => boolean;
  response: any;
  index: number;
  loading: boolean;
  error: boolean;
  dataSourceType: any;
  customWidgetConfig: any;
  previewMode: boolean;
  dateFilterModel: any;
}

const WidgetToRender: React.FC<WidgetToRenderProps> = ({
  removeWidget,
  editWidget,
  widget,
  validateData,
  response,
  index,
  loading,
  error,
  dataSourceType,
  customWidgetConfig,
  previewMode,
  dateFilterModel,
}) => {
  const { displayName, options, viewName, isCustom } = widget;

  const showCardClass = () => {
    if (loading && widget.alias !== 'DynamicTable') return styles[`no-card`];
    return options?.showCard ? styles[`card`] : styles[`no-card`];
  };
  const DeleteWidget = appAssets.icons.DELETE_ICON;
  const EditWidget = appAssets.icons.EDIT_ICON;
  const editing = useStore((state) => state.editing);
  const metricWidgets = ['IndividualMetric', 'MultipleSummaryMetrics'];
  const ChildComponent = libraryComponents[widget.alias];
  const { setState } = useWidgetContext();
  const hasTrend = widget.customWidgetConfig?.Trend?.length;
  const isTextBlock = widget.alias === 'TextBlock';

  useEffect(() => {
    setState({
      defaults: {
        ...('defaults' in options ? { ...options.defaults } : {}),
      },
    });
  }, [options]);

  let label = '';
  let hierarchyFiltersLabel = [];
  let advancedFiltersLabel = [];

  if (widget.customWidgetConfig?.Filters != null) {
    label =
      dateFilterModel?.displayName +
      ': ' +
      dateFilterModel?.startDate +
      ' - ' +
      dateFilterModel?.endDate;

    // Hierarchy Filters Label
    const selectedHierarchyFilters =
      widget.customWidgetConfig.Filters?.CurrentSelection?.HierarchyFilters;

    // Advanced Filters
    const selectedAdvancedFilters =
      widget.customWidgetConfig.Filters?.CurrentSelection?.AdvancedFilters;

    hierarchyFiltersLabel = getHierarchyFiltersLabelData(selectedHierarchyFilters);
    advancedFiltersLabel = getAdvancedFiltersLabelData(selectedAdvancedFilters);
  }

  return (
    <>
      <div
        className={`${showCardClass()} 
            ${metricWidgets.includes(widget.alias) ? styles[`metric-card`] : ''}`}
      >
        {editing && removeWidget ? (
          <div className={styles[`cardOverlay`]}>
            <div className={styles[`cardActions`]}>
              {widget?.isCustom && widget.payload?.QueriesMetadata == null ? (
                <EditWidget className={styles[`cardAction`]} onClick={() => editWidget(widget)} />
              ) : null}
              <DeleteWidget
                className={styles[`cardAction`]}
                onClick={() => removeWidget(parseInt(widget.config.i))}
              />
            </div>
          </div>
        ) : null}
        {options?.showTitle || options?.showActions ? (
          <div className={styles[`cardHeader`]}>
            {options?.showTitle && displayName?.length > 0 ? (
              <CardTitle
                displayName={displayName}
                viewName={widget.isCustom ? '' : viewName}
                isMetricWidget={metricWidgets.includes(widget.alias)}
                showWarning={showWarningValidation(
                  previewMode,
                  widget?.isMockData || false,
                  widget.alias
                )}
                warningValidData={warningValidData(response, widget.alias)}
                isActions={options?.showActions}
              />
            ) : null}
            {
              // Do not show the action area if data is loading.
              loading ? null : (
                <div className={styles[`card-info-actions`]}>
                  <CardHeaderActions {...{ actions: options?.actions, response }} />
                  {widget.isCustom &&
                    !isTextBlock &&
                    (hasTrend === 0 ||
                      hasTrend === undefined ||
                      widget.customWidgetConfig?.Filters?.CurrentSelection?.HierarchyFilters ||
                      widget.customWidgetConfig?.Filters?.CurrentSelection?.AdvancedFilters) && (
                      <DataTimeframe
                        timePeriodType={label}
                        hierarchyFiltersValues={hierarchyFiltersLabel}
                        advancedFiltersValues={advancedFiltersLabel}
                        hasTrend={hasTrend !== 0 ? true : false}
                        hasQuery={
                          widget.payload?.QueriesMetadata !== null &&
                          widget.payload?.QueriesMetadata !== undefined
                            ? true
                            : false
                        }
                      />
                    )}
                </div>
              )
            }
          </div>
        ) : null}
        {loading ? (
          <LoadingSpinner alias={widget.alias} />
        ) : validateData(response) || customWidgetConfig?.DataSource === 'generic' ? (
          <ChildComponent
            {...widget}
            id={index}
            response={response}
            loading={loading}
            error={error}
            dataSourceType={dataSourceType}
            previewMode={previewMode}
          />
        ) : (
          <DataNotFound />
        )}
        {
          // Do not show any action if loading.
          loading ? null : (
            <CardFooterActions {...{ actions: options?.actions, response, isCustom }} />
          )
        }
      </div>
    </>
  );
};

export default WidgetToRender;
